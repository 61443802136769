import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseURI = process.env.REACT_APP_BASE_API;

export const refreshToken = createAsyncThunk(
  "refresh/refreshToekn",
  async () => {
    try {
      const response = await axios.get(
        `${baseURI}Account/refresh-token`,
        {
          params: {
            refreshToken: localStorage.getItem("refreshToken"),
          },
        }
      );

      if (response?.data?.isSuccess) {
        localStorage.setItem(
          "accessToken",
          response?.data?.result?.jwtToken || null
        );
        localStorage.setItem(
          "refreshToken",
          response?.data?.result?.refreshToken || null
        );

        return response.data; // Return the entire response for potential use elsewhere
      } else {
        // Handle cases where refresh fails on the server-side (e.g., expired refresh token)
        throw new Error("Refresh token failed");
      }
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  loading: false, // Add loading state for refresh process
  error: null, // Add error state to store potential errors
};

export const refreshTokenSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Refresh token failed";
      });
  },
});

export default refreshTokenSlice.reducer;
