import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
const baseURI = process.env.REACT_APP_BASE_API;

export const referralCode = createAsyncThunk(
  "referral/referralCode",
  async ({ referralCode }) => {
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.post(
        `${baseURI}Account/use-referral-code`,
        {}, // Empty object for the request body
        {
          params: {
            referralCode: referralCode, // Pass UserMissionId as a query parameter
          },
          headers: {
            Authorization: localStorage.getItem('accessToken'),
          },
        }
      );
      // Return the data from the API response
      if (response.data.isSuccess) {
        return response.data;
      } else {
        // toast.error(response?.data?.errors?.[0]);
        return response.data;
      }
    } catch (error) {
      // toast.error(error?.message);
      // If there's an error, throw it so it can be handled
      throw error;
    }
  }
);

const initialState = { isSuccess: false };

export const referralCodeSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add a case for the pending state of fetchMission (API call is in progress)
      .addCase(referralCode.pending, (state) => {
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state of fetchMission (API call succeeded)
      .addCase(referralCode.fulfilled, (state, action) => {
        // Update the state with the data received from the API response
        state.isSuccess = action.payload.isSuccess;
      })
      // Add a case for the rejected state of fetchMission (API call failed)
      .addCase(referralCode.rejected, (state, action) => {
        // Handle the error here (e.g., display an error message)
      });
  },
});

// Export the async thunk action and the reducer
export default referralCodeSlice.reducer;
