import { useContext, createContext, useState, useEffect } from "react";
import {
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  TwitterAuthProvider,
} from "firebase/auth";

import { auth } from "./firebaseapi";
import axios from "axios";
import { toast } from "react-toastify";
const baseURI = process.env.REACT_APP_BASE_API;

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const TwitterSignIn = async (accessToken, isTwitterAccessToken = true) => {
    try {
      const response = await axios.post(
        `${baseURI}Account/register`,
        {
          accessToken: accessToken,
          isTwitterAccessToken,
        },
        {
          headers: {
            accept: "application/json",
          },
        }
      );
      // Handle the response here
      if (response?.data?.isSuccess) {
        localStorage.setItem(
          "accessToken",
          response?.data?.result?.jwtToken || null
        );
        localStorage.setItem(
          "refreshToken",
          response?.data?.result?.refreshToken || null
        );
        const isNewUser = response.data?.result?.isNewUser;
        localStorage.setItem("isNewUser", isNewUser);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("user", JSON.stringify(response.data?.result));
        setIsNewUser(isNewUser);
        setIsAuthenticated(true);
        if (response?.data?.isSuccess) {

          if (!isTwitterAccessToken) {
            window.location = "/profile/?isModel=true";
          }else{
            window.location = "/profile";
          }

       
        }
        return response?.data;
      } else {
        toast.error(response?.data?.errors?.[0]);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      setIsAuthenticated(false);
    }
  };

  const logOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem("isAuthenticated"));
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser !== null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, [user]);
  return (
    <AuthContext.Provider
      value={{
        user,
        isNewUser,
        // pageLoading,
        isAuthenticated,
        logOut,
        // setPageLoading,
        TwitterSignIn,
        setIsNewUser,
      }}
    >
      {children}
      {/* {pageLoading ? <Loader /> : <>{children}</>} */}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
