import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import BPM_Missions from "../../assets/logo/bpm_missions.png";
import Growth from "../../assets/icons/trending_up.svg";
import Redeem from "../../assets/icons/redeem.svg";
import CheckList from "../../assets/icons/checklist.svg";
import "./Home.css";
import { UserAuth } from "../../components/Authcontext";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import FAQModal from "../../components/FAQModal/FAQModal";
import axios from "axios";
import { fetchUser } from "../../redux/reducers/UserDetails";
import Loader from "../../components/Loader/Loader";

const Home = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { TwitterSignIn, user } = UserAuth();
  const navigate = useNavigate();
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      handleTwitterCallBack();
      navigate("/");
    }
  }, []);
  const handleSignIn = async () => {
    try {
      // const redirectURL =
      //   "https://f122-2402-a00-192-1840-f60d-67fd-e236-cd62.ngrok-free.app";
      // await TwitterSignIn();
      // dispatch(login());
      // navigate("/profile");
      // const authUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TWITER_REDIRECT_URI}&scope=tweet.read%20users.read%20follows.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain
      const authUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TWITER_REDIRECT_URI}&scope=tweet.read+users.read+offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`;
      // const authUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITER_CLIENT_ID}&redirect_uri=${redirectURL}&scope=tweet.read+users.read+offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`;

      window.location = authUrl;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleTwitterCallBack = async () => {
    try {
      setIsLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      localStorage.setItem("twitterCode", code);

      const baseURI = process.env.REACT_APP_BASE_API;
      const response = await axios.get(
        `${baseURI}Account/get-twitter-access-token`,
        {
          params: {
            code: code,
          },
        }
      );

      console.log("🚀 ~ response:", response);
      if (response?.data?.isSuccess) {
        console.log("🚀 ~ response?.data:", response?.data);
        const data = await TwitterSignIn(response?.data?.result?.access_token);
        console.log("🚀 ~ TwitterSignIn ~ data:", data);
      }
    } catch (error) {
      console.error("🚀 ~ handleTwitterCallBack ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const token = params.get("token"); // Replace 'paramName' with your parameter name
    const fetchData = async () => {
      try {
        const data = await TwitterSignIn(token, false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };
    if (token) {
      let userFromLocalStorage =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user"));
      dispatch(fetchUser(token));
      if (userFromLocalStorage) {
        window.location = "/profile/?isModel=true";
      } else {
        fetchData();
      }
    }
  }, []);

  useEffect(() => {
    //if user is already logged in redirect to profile page
    let isAuthenticated = localStorage.getItem("isAuthenticated") || false;
    let accessToken = localStorage.getItem("accessToken") || null;
    if (isAuthenticated && accessToken) {
      navigate("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  return (
    <>
      <Box className="home-page">
        {isLoading && <Loader loading={isLoading} />}
        <Container maxWidth="xl">
          <Box className="hero-section">
            <Box className="hero-section-logo">
              <img src={BPM_Missions} alt="BPM_Missions" />
            </Box>
            <Box
              className="hero-section-content"
              sx={{ textAlign: "center", color: "var(--white-color)" }}
            >
              <Box className="hero-section-title">CONNECT, ENGAGE, EARN</Box>
              <Box className="hero-content">
                Complete missions and earn points. Start your missions today!
              </Box>
            </Box>
            <Box className="hero-section-button">
              <button
                className="customPrimaryButton"
                onClick={() => handleSignIn()}
              >
                Connect X account
              </button>
              <button
                className="customSecondaryButton"
                onClick={(e) => handleClickOpen(e)}
              >
                See FAQs
              </button>
            </Box>
          </Box>

          {/* How it works section */}
          <Box className="work-container">
            <Box className="work-title">How It Works</Box>
            <Grid container columns={{ xs: 1, sm: 12, md: 9 }}>
              <Grid item xs={12} sm={6} md={3} className="work-detail-grid">
                <Box className="work-img">
                  <img src={CheckList} alt="CheckList" />
                </Box>
                <Box className="grid-item">Social media engagement</Box>
                <Box className="inner-text">
                  Earn points by interacting with social media posts. Gain
                  points through creating and sharing brand-related content.
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="work-detail-grid">
                <Box className="work-img">
                  <img src={Redeem} alt="Redeem" />
                </Box>
                <Box className="grid-item">Referrals and collaborations</Box>
                <Box className="inner-text">
                  Refer friends and collaborate on tasks for bonus points
                  through team missions, boosting community interaction.
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="work-detail-grid">
                <Box className="work-img">
                  <img src={Growth} alt="Growth" />
                </Box>
                <Box className="grid-item">
                  Missions earn points, points equal rewards
                </Box>
                <Box className="inner-text">
                  Participate in the listed missions, complete the required
                  tasks to gain points. Stack your points and treasures await.
                </Box>
              </Grid>
            </Grid>
          </Box>
          <FAQModal handleClose={(e) => handleClose(e)} open={open} />
        </Container>
      </Box>
    </>
  );
};

export default Home;
