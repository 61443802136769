import React from "react";
import Box from "@mui/material/Box";
//logo image import
// import Logo from "../../assets/logo/bpm_green.png";
// import Logo from "/bpm.svg";

const Navbar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "25px",
        alignItems: "center",
        boxShadow: "0px 2px 32px 0px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box>
        <img src="/bpm.svg" alt="BPMLogo" />
      </Box>
    </Box>
  );
};

export default Navbar;
