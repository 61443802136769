import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import SpinModal from "../../components/SpinModal/SpinModal";
import WalletModal from "../../components/WalletModal/WalletModal";
import ReferenceModal from "../../components/ReferenceModal/ReferenceModal";
import Countdown from "react-countdown";
import checkMark from "../../assets/icons/checkMark.svg";
import JoyStick from "../../assets/icons/joystick.svg";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation

//css file imports
import "./ProfilePage.css";
import WelcomeModal from "../../components/WelcomeModal/WelcomeModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchMission } from "../../redux/reducers/Mission";
import MissionModal from "../../components/MissionModal/MissionModal";
import { fetchUser } from "../../redux/reducers/UserDetails";
// import { UserAuth } from "../../components/Authcontext";
import Loader from "../../components/Loader/Loader";
import { logout } from "../../redux/reducers/AuthUser";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { missionList } = useSelector((state) => state.mission);
  const { userData, loading } = useSelector((state) => state.user);
  // const { loading } = useSelector((state) => state.loader);
  // const { isNewUser } = UserAuth();
  const [open, setOpen] = React.useState(false);
  const [spinModalopen, setSpinModalOpen] = React.useState(false);
  const [walletOpen, setWalletOpen] = React.useState(false);
  const [referenceOpen, setReferenceOpen] = React.useState(false);
  const [welcomeModalopen, setWelcomeModalOpen] = React.useState(false);
  const [currentMission, setCurrentMission] = React.useState({});
  const [endTime, setEndTime] = React.useState(0);

  const AvailableMissions = missionList?.filter((m) => m.missionStatusId === 1);

  const CompletedMissions = missionList?.filter((m) => m.missionStatusId === 2);

  const handleStartMission = ({ mission, event }) => {
    event.preventDefault();
    let newObj = { ...mission };
    //Display Only Xaman Wallet
    if (newObj.twitterUrl === "ConnectXamanWallet") {
      newObj.isXamanVisible = true;
      newObj.isPhantomVisible = false;
      setCurrentMission(newObj);
      setWalletOpen(true);
      //Display Only Phantom Wallet
    } else if (newObj.twitterUrl === "ConnectPhantomWallet") {
      newObj.isXamanVisible = false;
      newObj.isPhantomVisible = true;
      setCurrentMission(newObj);
      setWalletOpen(true);
    } else {
      //Open Mission Modal
      setCurrentMission(newObj);
      setOpen(true);
    }
  };

  const renderer = ({ hours, minutes, seconds }) => {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      setEndTime(0);
    }
    return <span>{`${hours}h ${minutes}m ${seconds}s`}</span>;
  };
  React.useEffect(() => {
    if (userData?.lastSpinTime) {
      let lastSpinDateTime = new Date(userData?.lastSpinTime);
      let currentTime = new Date();

      // Convert current time to UTC
      let currentUTCTime = new Date(
        currentTime.getTime() + currentTime.getTimezoneOffset() * 60000
      );

      // Add 1 day to lastSpinDateTime
      let nextSpinDateTime = new Date(lastSpinDateTime);
      nextSpinDateTime.setUTCDate(nextSpinDateTime.getUTCDate() + 1);

      // Calculate the difference in milliseconds
      let difference = nextSpinDateTime - currentUTCTime;
      // If the timestamp is in the past, set it to 0
      let timestamp = difference > 0 ? Date.now() + difference : 0;
      setEndTime(timestamp);
    }
  }, [userData]);

  React.useEffect(() => {
    //validate if user has signed in& token is available
    let isAuthenticated = localStorage.getItem("isAuthenticated") || false;
    let accessToken = localStorage.getItem("accessToken") || null;
    if (isAuthenticated && accessToken) {
      let isNewUser = JSON.parse(localStorage.getItem("isNewUser") || false);
      if (isNewUser) {
        setWelcomeModalOpen(true);
      }
      dispatch(fetchMission());
      dispatch(fetchUser());
    } else {
      //logout & redirect to home page if not signed in
      logout();
      dispatch(logout());
      localStorage.clear();
      navigate("/");
    }
  }, [open, spinModalopen, welcomeModalopen, walletOpen]);
  React.useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const isModel = params.get("isModel");
    if (isModel) {
      setWalletOpen(true);
      setCurrentMission({
        isXamanVisible: true,
        isPhantomVisible: true,
      });
    }
  }, [window.location]);

  if (loading) {
    return (
      <>
        <Loader loading={loading} />
      </>
    );
  }
  return (
    <>
      <Box className="profile-page">
        <Container maxWidth="xl">
          <Box className="profile-container">
            <Box className="user-profile">
              <img src={`${userData?.profileImageUrl}`} alt="UserProfile" />
              <Typography
                gutterBottom
                sx={{ fontSize: "var(--font-main-title)", marginTop: "15px" }}
              >
                {`@${userData?.twitterUserName}` || "NA"}
              </Typography>
            </Box>

            <Grid
              container
              columns={{ xs: 4, sm: 9, md: 9 }}
              className="profile-detail"
            >
              <Grid item xs={12} sm={3} md={3} className="work-detail-grid">
                <Box className="grid-item profile-text">
                  {" "}
                  {userData?.referral ?? 0}
                </Box>
                <Typography className="profile-text2">Referrals</Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className="work-detail-grid">
                <Box className="grid-item profile-text">
                  {" "}
                  {userData?.points ?? 0}
                </Box>
                <Typography className="profile-text2">
                  Mission Points
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box className="grid-item profile-text">
                  {" "}
                  {userData?.completedMissions ?? 0}
                </Box>
                <Typography className="profile-text2">
                  Missions complete
                </Typography>
              </Grid>
              <Box className="profile-button">
                <button
                  className="customProfileButton"
                  onClick={() => {
                    setWalletOpen(true);
                    setCurrentMission({
                      isXamanVisible: true,
                      isPhantomVisible: true,
                    });
                  }}
                >
                  {(userData &&
                    userData.xummWalletId !== null &&
                    userData.xummWalletId !== undefined) ||
                  (userData &&
                    userData.salonaTokenId !== null &&
                    userData.salonaTokenId !== undefined)
                    ? "Manage crypto wallets"
                    : "Connect crypto wallets"}
                </button>
                <button
                  className="customProfileButton"
                  onClick={() => setReferenceOpen(true)}
                >
                  Refer a friend
                </button>
              </Box>
            </Grid>
            <Box
              sx={{
                borderTop: "1px solid",
                borderImage: `linear-gradient(
                  -90deg,
                  rgba(56, 65, 74, 0) 0%,
                  #38414a 49.83%,
                  rgba(56, 65, 74, 0) 100%
                )`,
                margin: "50px 0px",
                borderImageSlice: 1,
                borderRadius: "1px",
              }}
            ></Box>
            <Box className="spinner-bg">
              <Grid
                container
                columns={{ xs: 1, sm: 1, md: 1 }}
                className="daily-spin-section"
              >
                <Grid item xs={12} md={3}>
                  <Box className="work-img">
                    <img src={JoyStick} alt="JoyStick" />
                  </Box>
                  <Box className="spin-text">DAILY SPIN TO WIN</Box>
                  <Box className="spin-text2">
                    Spin our daily wheel to win bonus points.
                  </Box>
                  <Box className="spin-button">
                    {endTime <= 0 && (
                      <button
                        className="customPrimaryButton"
                        onClick={() => setSpinModalOpen(true)}
                      >
                        Spin Now
                      </button>
                    )}
                    {endTime > 0 && (
                      <button className="customSpinTimerButton">
                        <Countdown
                          date={endTime}
                          renderer={renderer}
                        ></Countdown>
                      </button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {AvailableMissions && AvailableMissions.length > 0 && (
              <Box className="available-text">
                Available ({AvailableMissions.length})
              </Box>
            )}

            {AvailableMissions.map((data, index) => {
              return (
                <>
                  <Box className="mission-section">
                    <Grid
                      container
                      className="mission-section-detail available"
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={7}
                        className="mission-detail"
                      >
                        <Box className="mission-text">{data.title}</Box>
                        <Typography className="mission-text-p">
                          {data.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} lg={3}>
                        <Box className="grid-item pts-item">
                          {" "}
                          {data.point} PTS
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={3}
                        lg={2}
                        className="start-button-container"
                      >
                        <button
                          className="customPrimaryButton start-button"
                          onClick={(e) => {
                            handleStartMission({ mission: data, event: e });
                          }}
                        >
                          Start
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
            })}
            {CompletedMissions && CompletedMissions.length > 0 && (
              <Box className="available-text">
                Completed ({CompletedMissions.length})
              </Box>
            )}

            {CompletedMissions.map((data, index) => {
              return (
                <>
                  {" "}
                  <Box className="mission-section">
                    <Grid container className="mission-section-detail">
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={7}
                        className="mission-detail"
                      >
                        <Box
                          className="mission-text"
                          sx={{ color: "var(--white-color)" }}
                        >
                          {data.title}
                        </Box>
                        <Typography
                          className="mission-text-p"
                          sx={{ color: "var(--disabled-color)" }}
                        >
                          {data.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} lg={3}>
                        <Box
                          className="grid-item pts-item"
                          sx={{
                            color: "var(--disabled-color)",
                          }}
                        >
                          {data.point} PTS
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={3}
                        lg={2}
                        className="start-button-container"
                      >
                        <button className="customCompletedButton">
                          <img src={checkMark} alt="checkMark" />
                          &nbsp;Complete
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
            })}
          </Box>
          <ReferenceModal
            referenceOpen={referenceOpen}
            handleClose={() => setReferenceOpen(false)}
            data={userData}
          />
          <WalletModal
            walletOpen={walletOpen}
            handleClose={() => setWalletOpen(false)}
            data={currentMission}
          />
          <SpinModal
            spinModalopen={spinModalopen}
            handleClose={() => {
              setSpinModalOpen(false);
            }}
          />

          <MissionModal
            open={open}
            handleClose={() => setOpen(false)}
            isButton={true}
            data={currentMission}
          />
          <WelcomeModal
            welcomeModalopen={welcomeModalopen}
            handleClose={() => setWelcomeModalOpen(false)}
            data={userData}
          />
        </Container>
      </Box>
    </>
  );
};

export default ProfilePage;
