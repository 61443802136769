import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
// Importing environment variables
const baseURI = process.env.REACT_APP_BASE_API;

// Define an async thunk action to fetch FAQ data from the API
export const fetchFAQ = createAsyncThunk(
  "faq/fetchFAQ",
  async ({ PageNumber, PageSize }) => {
    try {
      // startLoading(); // Dispatch startLoading action to indicate the start of fetching
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.get(`${baseURI}Faq/get-faqs`, {
        params: {
          PageNumber: PageNumber,
          PageSize: PageSize,
        },
      });
      // Return the data from the API response
      return response?.data;
    } catch (error) {
      // toast.error(error?.message);
      throw error;
    } finally {
      // endLoading(); // Dispatch endLoading action to indicate the end of fetching, regardless of success or failure
    }
  }
);

const initialState = { faqList: [], faqCount: 0 };

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add a case for the pending state (API call is in progress)
      .addCase(fetchFAQ.pending, (state) => {
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state (API call succeeded)
      .addCase(fetchFAQ.fulfilled, (state, action) => {
        // Update the state with the data received from the API response

        state.faqList = action?.payload?.result;
        state.faqCount = action?.payload?.totalRecords;
      })
      // Add a case for the rejected state (API call failed)
      .addCase(fetchFAQ.rejected, (state, action) => {
        // Handle the error here (e.g., display an error message)
      });
  },
});

// Export the async thunk action and the reducer
export const {} = faqSlice.actions;
export default faqSlice.reducer;
