import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURI = process.env.REACT_APP_BASE_API;

// Define an async thunk action to fetch mission data from the API
export const fetchMission = createAsyncThunk(
  "mission/fetchMission",
  async () => {
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.get(`${baseURI}User/get-users-missions`, {
        // params: {
        //   PageNumber: 0,
        //   PageSize: 10000,
        //   SortProperty: "DateCreated",
        // },
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });
      // Return the data from the API response
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateMission = createAsyncThunk(
  "mission/updateMission",
  async ({ MissionId }) => {
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.post(
        `${baseURI}User/complete-mission`,
        {}, // Empty object for the request body
        {
          params: {
            MissionId: MissionId, // Pass UserMissionId as a query parameter
          },
          headers: {
            Authorization: localStorage.getItem('accessToken'),
          },
        }
      );
      // Return the data from the API response
      return response.data;
    } catch (error) {
      // toast.error(error?.message);
      // dispatch(logout()); // If there's an error, throw it so it can be handled
      throw error;
    }
  }
);

const initialState = { missionList: [], missionCount: 0 };

export const missionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add a case for the pending state of fetchMission (API call is in progress)
      .addCase(fetchMission.pending, (state) => {
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state of fetchMission (API call succeeded)
      .addCase(fetchMission.fulfilled, (state, action) => {
        // Update the state with the data received from the API response
        state.missionList = action.payload.result;
        state.missionCount = action.payload.totalRecords;
      })
      // Add a case for the rejected state of fetchMission (API call failed)
      .addCase(fetchMission.rejected, (state, action) => {
        // Handle the error here (e.g., display an error message)
      })
      // Add a case for the pending state of updateMission (API call is in progress)
      .addCase(updateMission.pending, (state) => {
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state of updateMission (API call succeeded)
      .addCase(updateMission.fulfilled, (state, action) => {
        // Update the state with the data received from the API response if needed
        // For example, if updateMission returns data that needs to be incorporated into the state
      })
      // Add a case for the rejected state of updateMission (API call failed)
      .addCase(updateMission.rejected, (state, action) => {
        // Handle the error here (e.g., display an error message)
      });
  },
});

// Export the async thunk action and the reducer
export default missionSlice.reducer;
