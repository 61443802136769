import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
const initialState = {
  isAuthenticated: false,
  authToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
      // toast.success("Login Successful")
      // state.authToken = localStorage.getItem("accessToken");
    },
    logout: (state) => {
      // toast.success("Logout Success")
      state.isAuthenticated = false;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
