import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./SpinModal.css";
import { Box, Typography } from "@mui/material";
import { dailySpinner } from "../../redux/reducers/Spinner";
import { Wheel } from "react-custom-roulette";
import { useDispatch } from "react-redux";
import downArrow from '../../assets/downArrow.png';


const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "var(--background-color-light)", // Set the background color for the dialog
    color: "var(--white-color)",
    borderRadius: "20px",
    border: "1px solid rgba(56, 65, 74, 1)",
    maxWidth: "550px",
    textAlign: "center",
  },
});

const SpinModal = ({ handleClose, spinModalopen }) => {
  const dispatch = useDispatch();
  const [mustSpin, setMustSpin] = React.useState(false);
  const [prizeNumber, setPrizeNumber] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState(null); //store the win option value after spin
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const data = [
    { option: "3000" },
    { option: "100" },
    { option: "150" },
    { option: "200" },
    { option: "1000" },
    { option: "250" },
    { option: "300" },
    { option: "50" },
  ];

  const bgs = [
    "#C3FF55",
    "#EFE8FF",
    "#E1D4FF",
    "#CCB1FF",
    "#C3FF55",
    "#B485FF",
    "#9747FF",
    "#F7F2FF",
  ];

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const spinnerStop = () => {
    setMustSpin(false);
    let arrayIndex = prizeNumber === 0 ? data.length - 1 : prizeNumber - 1;
    setSelectedOption(data[arrayIndex].option);
    dispatch(
      dailySpinner({
        points: data[arrayIndex].option,
      })
    );
    setIsSubmitted(true);
  };

  React.useEffect(() => {
    setIsSubmitted(false);
  }, []);
  return (
    <>
      <StyledDialog
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={spinModalopen}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            fontWeight: "var(--font-weight-400)",
            textTransform: "uppercase",
            fontSize: "var(--font-main-title)",
            fontFamily: "Impact, sans-serif !important",
          }}
          id="customized-dialog-title"
        >
          Daily Spin
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--white-color)",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            borderRadius: "15px",
            color: "var(--white-color)",
          }}
        >
          {" "}
          {!isSubmitted ? (
            <>
              <Typography
                sx={{
                  fontSize: "var(--font-large-content)",
                  color: "var(--primary-color)",
                  fontFamily: "Inter !important",
                }}
              >
                Spin daily with a chance to win more points!
              </Typography>{" "}
              <Box
                className="App"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    // spinDuration={0.5}
                    outerBorderWidth={0}
                    radiusLineWidth={1}
                    radiusLineColor="white"
                    pointerProps={{
                      src: downArrow,
                      style: {
                        width: "20px",
                        position: "absolute",
                        top: "0",
                        left: "48%",
                        backgroundColor: "transparent",
                      },
                    }}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    backgroundColors={bgs}
                    onStopSpinning={() => {
                      spinnerStop();
                    }}
                    textStyle={{
                      fontFamily: "Impact, sans-serif",
                      fontWeight: "bold",
                      fontSize: "30px",
                      color: "#000",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "auto",
                    marginBottom: "20px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    disabled={mustSpin}
                    className="customSpinPrimaryButton"
                    onClick={() => handleSpinClick()}
                  >
                    SPIN
                  </button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    fontSize: "var(--font-xl-title)",
                    fontWeight: "var(--font-weight-400)",
                    fontFamily: "Impact, sans-serif !important",
                    color: "var(--light-green-color)",
                  }}
                >
                  + {selectedOption} PTS
                </Box>
                <Box
                  sx={{
                    margin: "32px 0px",
                    fontSize: "var(--font-size-base)",
                    fontFamily: "Inter Tight !important",
                  }}
                >
                  Congratulations, you have earned {selectedOption} points! Come
                  back tomorrow to spin the wheel again.
                </Box>{" "}
                <Box
                  sx={{
                    borderTop: "1px solid",
                    borderImage: `linear-gradient(
                  -90deg,
                  rgba(56, 65, 74, 0) 0%,
                  #38414a 49.83%,
                  rgba(56, 65, 74, 0) 100%
                )`,
                    margin: "10px 0px",
                    borderImageSlice: 1,
                    borderRadius: "1px",
                  }}
                ></Box>
                <Box
                  sx={{
                    margin: "20px 0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    disabled={mustSpin}
                    className="customPrimaryButton"
                    onClick={() => handleClose()}
                  >
                    Back to Missions
                  </button>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default SpinModal;
