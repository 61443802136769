
export function getDeviceOS() {
  var userAgent = navigator.userAgent;

  if (/Android/i.test(userAgent)) {
    return "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  } else if (/Windows Phone/i.test(userAgent)) {
    return "Windows Phone";
  } else if (/Mac/i.test(userAgent)) {
    return "Mac OS";
  } else if (/Linux/i.test(userAgent)) {
    return "Linux";
  } else if (/Windows/i.test(userAgent)) {
    return "Windows";
  } else {
    return "Unknown";
  }
}

export const getBrowserDetails = () => {
  let browserName = '';

  if (navigator.userAgent.indexOf('Chrome') != -1) {
    browserName = 'Google Chrome';
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    browserName = 'Mozilla Firefox';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    browserName = 'Apple Safari';
  } else if (navigator.userAgent.indexOf('MSIE') != -1 || navigator.userAgent.indexOf('Trident/') != -1) {
    browserName = 'Internet Explorer';
  } else if (navigator.userAgent.indexOf('Edge') != -1) {
    browserName = 'Microsoft Edge';
  } else if (navigator.userAgent.indexOf('Opera') != -1 || navigator.userAgent.indexOf('OPR') != -1) {
    browserName = 'Opera';
  } else if (navigator.userAgent.indexOf('Brave') != -1) {
    browserName = 'Brave';
  } else {
    browserName = 'Unknown';
  }
  // console.log("🚀 ~ getBrowserDetails ~ browserName:", browserName)
  // alert(navigator.userAgent)

  return browserName
}
