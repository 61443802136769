import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import "./MissionModal.css";
import CircularProgress from "@mui/material/CircularProgress";
import { updateMission } from "../../redux/reducers/Mission";
import { useDispatch } from "react-redux";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "var(--background-color-light)", // Set the background color for the dialog
    color: "var(--white-color)",
    borderRadius: "20px",
    border: "1px solid rgba(56, 65, 74, 1)",
    maxWidth: "750px",
  },
  "& .MuiDialogContent-root": {
    padding: "10px 24px",
  },
});

const MissionModal = ({ handleClose, open, isButton, data }) => {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [timer, setTimer] = React.useState(null);

  const handleClick = (twitterUrl) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let intentUrl = "";
    let fallback = false;

    // Helper function to extract information from the URL
    const extractInfoFromUrl = (url) => {
      const urlObj = new URL(url);
      const pathSegments = urlObj.pathname
        .split("/")
        .filter((segment) => segment);

      if (
        pathSegments.length === 3 &&
        pathSegments[0] &&
        pathSegments[1] === "status" &&
        pathSegments[2]
      ) {
        // This is a tweet URL
        return { type: "status", id: pathSegments[2] };
      } else if (pathSegments.length === 1) {
        // This is a user profile URL
        return { type: "profile", username: pathSegments[0] };
      } else if (urlObj.pathname === "/search") {
        // This is a search URL
        const query = urlObj.searchParams.get("q");
        return { type: "search", query };
      } else if (urlObj.pathname.startsWith("/hashtag")) {
        // This is a hashtag URL
        const hashtag = pathSegments[1];
        return { type: "hashtag", hashtag };
      } else if (urlObj.pathname === "/compose/tweet") {
        // This is a compose URL
        const message = urlObj.searchParams.get("text");
        return { type: "compose", message };
      } else if (urlObj.pathname === "/mentions") {
        // This is a mentions URL
        return { type: "mentions" };
      } else if (urlObj.pathname === "/notifications") {
        // This is a notifications URL
        return { type: "notifications" };
      } else if (urlObj.pathname.endsWith("/lists")) {
        // This is a user lists URL
        const username = pathSegments[0];
        return { type: "lists", username };
      } else if (urlObj.pathname.startsWith("/i/moments")) {
        // This is a moments URL
        const momentId = pathSegments[2];
        return { type: "moments", momentId };
      }
      return null;
    };

    const info = extractInfoFromUrl(twitterUrl);
    // Detect the device and set the intent URL accordingly
    if (/android/i.test(userAgent)) {
      if (info) {
        switch (info.type) {
          case "status":
            intentUrl = `twitter://status?id=${info.id}`;
            break;
          case "profile":
            intentUrl = `https://twitter.com/intent/user?screen_name=${info.username}`;
            break;
          case "search":
            intentUrl = `twitter://search?query=${encodeURIComponent(
              info.query
            )}`;
            break;
          case "hashtag":
            intentUrl = `twitter://search?query=%23${encodeURIComponent(
              info.hashtag
            )}`;
            break;
          case "compose":
            intentUrl = `twitter://post?message=${encodeURIComponent(
              info.message
            )}`;
            break;
          case "mentions":
            intentUrl = `twitter://mentions`;
            break;
          case "notifications":
            intentUrl = `twitter://notifications`;
            break;
          case "lists":
            intentUrl = `twitter://user_lists?screen_name=${encodeURIComponent(
              info.username
            )}`;
            break;
          case "moments":
            intentUrl = `twitter://moments?id=${encodeURIComponent(
              info.momentId
            )}`;
            break;
          default:
            intentUrl = twitterUrl;
            fallback = true;
        }
      } else {
        intentUrl = twitterUrl;
        fallback = true;
      }
      // Attempt to open the Twitter intent URL
      window.open(intentUrl, "_blank");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS device
      if (info) {
        if (info.type === "status") {
          intentUrl = `twitter://status?id=${info.id}`;
        } else if (info.type === "profile") {
          intentUrl = `twitter://user?screen_name=${info.username}`;
        } else if (info.type === "search") {
          intentUrl = `twitter://search?query=${info.query}`;
        } else if (info.type === "hashtag") {
          intentUrl = `twitter://search?query=%23${info.hashtag}`;
        } else if (info.type === "compose") {
          intentUrl = `twitter://post?message=${encodeURIComponent(
            info.message
          )}`;
        } else if (info.type === "mentions") {
          intentUrl = `twitter://mentions`;
        } else if (info.type === "notifications") {
          intentUrl = `twitter://notifications`;
        } else if (info.type === "lists") {
          intentUrl = `twitter://user_lists?screen_name=${info.username}`;
        } else if (info.type === "moments") {
          intentUrl = `twitter://moments?id=${info.momentId}`;
        }
      } else {
        intentUrl = twitterUrl; // Fallback to web URL if parsing fails
        fallback = true;
      }
    } else {
      // Fallback for other devices (opens the URL in a browser)
      intentUrl = twitterUrl;
      fallback = true;
    }

    if (fallback) {
      window.open(intentUrl, "_blank");
    } else {
      window.location.href = intentUrl;
    }
  };


  const onSubmit = (e) => {
    e.preventDefault();
    if (isSubmitted) {
      handleClose(false);
      return;
    }
    setIsLoading(true);
    setTimeout(() => {
      let validateTwitterRegex =
        /^(?:http:\/\/|https:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/(?:#!\/)?(?:[\w-]*\/)*([\w-]*)/;

      if (validateTwitterRegex.test(data.twitterUrl)) {
        handleClick(data.twitterUrl); // Use handleClick to open the URL
      } else {
        window.open(data.twitterUrl, "_blank");
      }
    }, 900);
    const minTime = data.minSeconds;
    const maxTime = data.maxSeconds;

    const randomValue =
      Math.floor(Math.random() * (maxTime - minTime)) + minTime;

    const timeout = setTimeout(() => {
      if (open) {
        dispatch(updateMission({ MissionId: data.id }));
        setIsSubmitted(true);
        setIsLoading(false);
      }
    }, randomValue * 1000);
    setTimer(timeout);
  };

  React.useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  React.useEffect(() => {
    setIsLoading(false);
    setIsSubmitted(false);
    if (timer) {
      clearTimeout(timer);
    }
  }, [open]);

  if (!open) return <></>;

  return (
    <>
      <StyledDialog
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            textAlign: "center",
            fontWeight: "var(--font-weight-400)",
            textTransform: "uppercase",
            fontSize: "var(--font-main-title)",
            fontFamily: "Impact, sans-serif !important",
          }}
          id="customized-dialog-title"
        >
          {isSubmitted ? "MISSION COMPLETE" : "Mission"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--white-color)",
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {!isSubmitted && (
            <>
              <Box className="mission-box-title">
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "var(--font-large-content)",
                    fontWeight: "var(--font-weight-700)",
                    color: "var(--light-green-color)",
                    fontFamily: "Inter Tight !important",
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "var(--font-size-base)",
                    fontWeight: "var(--font-weight-500)",
                    fontFamily: "Inter Tight !important",
                  }}
                >
                  {data.description}
                </Typography>
              </Box>
            </>
          )}
          {isSubmitted && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "var(--font-xl-title)",
                  fontWeight: "var(--font-weight-400)",
                  color: "var(--light-green-color)",
                  fontFamily: "Impact, sans-serif !important",
                }}
              >
                + {data.point} PTS
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontSize: "var(--font-large-content)",
                  fontFamily: "Inter Tight !important",
                }}
              >
                Congratulations, you have successfully completed this mission!
              </Typography>
            </Box>
          )}
        </DialogContent>

        {isButton && (
          <>
            {!isSubmitted ? (
              <></>
            ) : (
              <Box
                sx={{
                  borderTop: "1px solid",
                  borderImage: `linear-gradient(
                  -90deg,
                  rgba(56, 65, 74, 0) 0%,
                  #38414a 49.83%,
                  rgba(56, 65, 74, 0) 100%
                )`,
                  margin: "10px 0px",
                  borderImageSlice: 1,
                  borderRadius: "1px",
                }}
              ></Box>
            )}
            <Box className="start-mission-button">
              <>
                {!isLoading && (
                  <>
                    <button
                      className="customPrimaryButton"
                      onClick={(e) => onSubmit(e)}
                    >
                      {!isSubmitted ? "Start Mission" : "Back to Missions"}
                    </button>
                  </>
                )}
                {isLoading && (
                  <>
                    <CircularProgress
                      sx={{
                        color: "var(--light-green-color)",
                        marginBottom: "35px", // Add margin bottom for spacing
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "var(--font-size-base)",
                        position: "absolute",
                        bottom: "-20px", // Adjust this value to position the label
                        left: "50%",
                        margin: "20px 0px",
                        transform: "translateX(-50%)",
                        color: "var(--light-green-color)",
                      }}
                    >
                      Validating Mission...
                    </Typography>
                  </>
                )}
              </>
            </Box>
          </>
        )}
      </StyledDialog>
    </>
  );
};

export default MissionModal;
