import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import "./FAQModal.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchFAQ } from "../../redux/reducers/FAQ";
import { useEffect } from "react";
const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "var(--background-color-light)", // Set the background color for the dialog
    color: "var(--white-color)",
    borderRadius: "20px",
    border: "1px solid rgba(56, 65, 74, 1)",
    maxWidth: "750px",
  },
  "& .MuiDialogContent-root": {
    // Remove the space between "&" and ".MuiDialogContent-root"
    height: "500px !important",
  },
});

const FAQModal = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  // const { faqList, faqCount } = useSelector((state) => state.faq);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [wasLastList, setWasLastList] = React.useState(false); // setting a flag to know the last list
  const [currentFAQList, setCurrentFAQList] = React.useState([]);
  const listInnerRef = React.useRef();

  const onScroll = (e) => {
    e.preventDefault();
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageNumber((pageNumber) => pageNumber + 1);
      }
    }
  };
  useEffect(() => {
    if (!open) {
      setCurrentFAQList([]);
      setPageNumber(0);
      setWasLastList(false);
    }
    const fetchData = async () => {
      const response = await dispatch(
        fetchFAQ({ PageNumber: pageNumber, PageSize: pageSize })
      );
      const newFaqList = response?.payload?.result || [];
      if (newFaqList.length === 0) {
        setWasLastList(true);
        return;
      }
      setCurrentFAQList([...currentFAQList, ...newFaqList]);
    };

    if (!wasLastList && open) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, open]);
  return (
    <>
      <StyledDialog
        fullWidth={true}
        onClose={(e) => {
          handleClose(e);
          setPageNumber(0);
          setCurrentFAQList([]);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            fontWeight: "var(--font-weight-400)",
            textTransform: "uppercase",
            fontSize: "var(--font-main-title)",
            fontFamily: "Impact, sans-serif !important",
          }}
          id="customized-dialog-title"
        >
          Frequently Asked Questions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(e) => {
            handleClose(e);
            setPageNumber(0);
            setWasLastList(false);
            setCurrentFAQList([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--white-color)",
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent onScroll={(e) => onScroll(e)} ref={listInnerRef}>
          <>
            {currentFAQList?.map((faq, index) => (
              <Box className="faq-box-title" key={index}>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "var(--font-large-content)",
                    fontFamily: "Inter Tight !important",
                    fontWeight: "var(--font-weight-700)",
                  }}
                >
                  {faq?.title}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "var(----font-size-base)",
                    fontFamily: "Inter Tight !important",
                    fontWeight: "var(--font-weight-500)",
                  }}
                >
                  {faq?.description}
                </Typography>
              </Box>
            ))}
          </>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default FAQModal;
