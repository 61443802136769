import { combineReducers } from "redux";
import faqReducer from "./FAQ";
import authReducer from "./AuthUser";
import Loader from "./Loader";
import missionReducer from "./Mission";
import userReducer from "./UserDetails";
import spinnerReducer from "./Spinner";
import referralCodeReducer from "./ReferralCode";
import walletReducer from "./Wallet";
import refreshTokenReducer from "./RefreshToken";

const reducers = combineReducers({
  faq: faqReducer,
  auth: authReducer,
  user: userReducer,
  loader: Loader,
  mission: missionReducer,
  spinner: spinnerReducer,
  referral: referralCodeReducer,
  wallet: walletReducer,
  refresh: refreshTokenReducer,
});

export default reducers;
