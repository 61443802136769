import { Routes, Route } from "react-router-dom";
import Home from "../../pages/Home/Home";
import Error404 from "../../pages/Error404/Error404";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Navigation;