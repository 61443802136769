import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Discord from "../../assets/icons/discord.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Telegram from "../../assets/icons/telegram.svg";
import "./Footer.css";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
// import { Link } from "react-router-dom";

function Footer() {
  const theme = useTheme();

  return (
    <Box className="footer-container">
      <Container maxWidth="xl">
        <Grid
          container
          // columnSpacing={{ xs: 1, sm: 2, md: 1 }}
          spacing={theme.spacing(4)}
        >
          <Grid item xs={12} md={7} lg={8}>
            <Box className="footer-title">
              Stay&nbsp;
              <span style={{ color: "var(--light-green-color)" }}>
                Connected
              </span>
            </Box>
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <List>
              <ListItem className="footer-list-item">
                <ListItemAvatar className="list-item-avatar">
                  <img src={Twitter} alt="Twitter(X)" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <a
                      href="https://twitter.com/missionbpm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      X (BPM)
                    </a>
                  }
                  secondary={
                    <a
                      href="https://twitter.com/missionbpm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @MissionBPM
                    </a>
                  }
                  className="footer-list-item-secondary"
                />
              </ListItem>
              <ListItem className="footer-list-item">
                <ListItemAvatar className="list-item-avatar">
                  <img src={Twitter} alt="Twitter(X)" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <a
                      href="https://twitter.com/bpmrevellers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      X (Revellers)
                    </a>
                  }
                  secondary={
                    <a
                      href="https://twitter.com/bpmrevellers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @RVLLRS
                    </a>
                  }
                  className="footer-list-item-secondary"
                />
              </ListItem>
              <ListItem className="footer-list-item">
                <ListItemAvatar className="list-item-avatar">
                  <img src={Discord} alt="DiscordLogo" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <a
                      href="https://discord.com/invite/xJAmHkwNGv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Discord
                    </a>
                  }
                  secondary={
                    <a
                      href="https://discord.com/invite/xJAmHkwNGv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BPM
                    </a>
                  }
                  className="footer-list-item-secondary"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
