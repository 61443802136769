import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURI = process.env.REACT_APP_BASE_API;

// Define an async thunk action to fetch mission data from the API
export const fetchXaman = createAsyncThunk("Xumm/SignIn", async () => {
  // authToken passed as a parameter
  try {
    // Make the API request using axios (or any other HTTP client)
    const response = await axios.get(`${baseURI}Xumm/SignIn`, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
    });

    // Return the data from the API response
    return response.data;
  } catch (error) {
    // If there's an error, throw it so it can be handled
    throw error;
  }
});
export const connectSalona = createAsyncThunk(
  "Xumm/SignIn",
  async ({ salonaTokenId }) => {
    // authToken passed as a parameter
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.post(`${baseURI}User/update-salona-token`, {},{
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          salonaTokenId,
        },
      });
      // Return the data from the API response
      return response.data;
    } catch (error) {
      // If there's an error, throw it so it can be handled
      throw error;
    }
  }
);
export const walletDisconnect = createAsyncThunk(
  "Xumm/SignIn",
  async ({ data }) => {
    // authToken passed as a parameter
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.post(
        `${baseURI}Account/disconnect-wallet`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      // Return the data from the API response
      return response.data;
    } catch (error) {
      // If there's an error, throw it so it can be handled
      throw error;
    }
  }
);
export const getWalletStatus = createAsyncThunk(
  "Xumm/WalletStatus",
  async ({ data }) => {
    // authToken passed as a parameter
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.get(
        `${baseURI}Xumm/get-users-xumm-wallet-status`,
   
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      // Return the data from the API response
      return response.data;
    } catch (error) {
      // If there's an error, throw it so it can be handled
      throw error;
    }
  }
);

const initialState = { xamanData: null };

export const walletSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add a case for the pending state of fetchMission (API call is in progress)
      .addCase(fetchXaman.pending, (state) => {
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state of fetchXaman (API call succeeded)
      .addCase(fetchXaman.fulfilled, (state, action) => {
        // Update the state with the data received from the API response
        state.xamanData = action.payload.result;
      })
      // Add a case for the rejected state of fetchXaman (API call failed)
      .addCase(fetchXaman.rejected, (state, action) => {
        // Handle the error here (e.g., display an error message)
      });
    // Add a case for the pending state of updateMission (API call is in progress)
  },
});

// Export the async thunk action and the reducer
export default walletSlice.reducer;
