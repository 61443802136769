import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// Importing environment variables
const baseURI = process.env.REACT_APP_BASE_API;

export const fetchUser = createAsyncThunk("user/fetchUser", async (token) => {
  const response = await axios
    .get(`${baseURI}User/get-user-details`, {
      headers: {
        Authorization: token ? token: localStorage.getItem("accessToken"),
      },
    })
    .then((response) => {
      // Return the data from the API response
      return response.data;
    })
    .catch((error) => {
      return error;
    });
   return response;
});

const initialState = { userData: {}, loading: false };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add a case for the pending state (API call is in progress)
      .addCase(fetchUser.pending, (state) => {
        // state.loading = true;
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state (API call succeeded)
      .addCase(fetchUser.fulfilled, (state, action) => {
        // Update the state with the data received from the API response
        state.userData = action?.payload?.result ?? {};
        // state.loading = false;

        // state.userCount = action.payload.totalRecords;
      })
      // Add a case for the rejected state (API call failed)
      .addCase(fetchUser.rejected, (state, action) => {
        // state.loading = false;
        // Handle the error here (e.g., display an error message)
      });
  },
});

// Export the async thunk action and the reducer
export const {} = userSlice.actions;
export default userSlice.reducer;
