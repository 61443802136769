import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURI = process.env.REACT_APP_BASE_API;

export const dailySpinner = createAsyncThunk(
  "spinner/dailySpinner",
  async ({ points }) => {
    try {
      // Make the API request using axios (or any other HTTP client)
      const response = await axios.put(
        `${baseURI}User/daily-spin`,
        {}, // Empty object for the request body
        {
          params: {
            points: points, // Pass UserMissionId as a query parameter
          },
          headers: {
            Authorization: localStorage.getItem('accessToken'),
          },
        }
      );
      // Return the data from the API response
      return response.data;
    } catch (error) {
      // If there's an error, throw it so it can be handled
      throw error;
    }
  }
);

const initialState = { spinnerList: [], spinnerCount: 0 };

export const spinnerSlice = createSlice({
  name: "spinner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add a case for the pending state of fetchMission (API call is in progress)
      .addCase(dailySpinner.pending, (state) => {
        // You can update the state here if needed (e.g., setting loading state)
      })
      // Add a case for the fulfilled state of fetchMission (API call succeeded)
      .addCase(dailySpinner.fulfilled, (state, action) => {
        // Update the state with the data received from the API response
        state.missionList = action.payload.result;
        state.missionCount = action.payload.totalRecords;
      })
      // Add a case for the rejected state of fetchMission (API call failed)
      .addCase(dailySpinner.rejected, (state, action) => {
        // Handle the error here (e.g., display an error message)
      });
  },
});

// Export the async thunk action and the reducer
export default spinnerSlice.reducer;
