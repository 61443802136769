import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation
import "./AuthNavBar.css";
//logo image import
import MissionLogo from "../../assets/logo/bpm_missions.png";
import { UserAuth } from "../../components/Authcontext";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/reducers/AuthUser";

const AuthNavBar = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { logOut } = UserAuth();
  const dispatch = useDispatch();
  const handleLogOut = async () => {
    try {
      await logOut();
      dispatch(logout());
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box sx={{ boxShadow: "0px 2px 32px 0px rgba(0, 0, 0, 0.08)" }}>
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Box className="auth-navbar">
          <Box className="profile-page-logo">
            <Box className="bpm-profile-logo">
              <img src="/bpm.svg" alt="BPMLogo" />
            </Box>
            <Box className="border-line"></Box>
            <Box className="mission-profile-logo">
              <img src={MissionLogo} alt="MissionLogo" />
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "end",
              position: "absolute",
              top: "0px",
              right: "25px",
              "@media (max-width: 575px)": {
                right: "0px",
              },
            }}
          >
            <button
              onClick={handleLogOut} // Call handleLogout function on button click
              className="customProfileButton"
            >
              Logout
            </button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthNavBar;
