import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";
import "./WelcomeModal.css";
import AstronautCurrency from "../../assets/images/astronaut_currency.png";
import { useDispatch, useSelector } from "react-redux";
import { referralCode } from "../../redux/reducers/ReferralCode";
// import { UserAuth } from "../Authcontext";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "var(--background-color-light)", // Set the background color for the dialog
    // color: "var(--white-color)",
    borderRadius: "20px",
    border: "1px solid rgba(56, 65, 74, 1)",
    maxWidth: "750px",
    textAlign: "center",
  },
});

const WelcomeModal = ({ handleClose, welcomeModalopen }) => {
  // const { setIsNewUser } = UserAuth();

  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.referral.isSuccess);

  const [isreferralCode, setIsReferralCode] = React.useState("");
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const redeemCode = async () => {
    setIsSubmit(true);
    dispatch(referralCode({ referralCode: isreferralCode }));
  };
  React.useEffect(() => {
    if (isSubmit) {
      if (isSuccess) {
        // setIsNewUser(false);
        handleClose(false);
        localStorage.removeItem("isNewUser");
      } else {
        setErrorMessage("Invalid referral code. Please try again.");
      }
    }
  }, [isSuccess, isSubmit]);
  React.useEffect(() => {
    if (isreferralCode.length === 0) {
      setErrorMessage("");
    }
  }, [isreferralCode]);
  return (
    <>
      <StyledDialog
        fullWidth={true}
        onClose={() => {
          handleClose();
          // setIsNewUser(false);
          localStorage.removeItem("isNewUser");
        }}
        aria-labelledby="customized-dialog-title"
        open={welcomeModalopen}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            fontWeight: "var(--font-weight-400)",
            textTransform: "uppercase",
            fontSize: "var(--font-sub-title)",
            color: "var(--white-color)",
            fontFamily: "Impact, sans-serif",
          }}
          id="customized-dialog-title"
        >
          WELCOME TO BPM MISSIONS!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            // setIsNewUser(false);
            localStorage.removeItem("isNewUser");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--white-color)",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            borderRadius: "15px",
          }}
        >
          <Box className="welcome-img">
            <img src={AstronautCurrency} alt="AstronautCurrency" />
          </Box>
          {/* <Typography
            sx={{
              fontSize: "var(--font-xl-title)",
              fontWeight: "var(--font-weight-400)",
              color: "var(--light-green-color)",
              fontFamily: "Impact, sans-serif",
            }}
          >
            + 5000 PTS
          </Typography> */}

          <Box
            sx={{
              borderTop: "1px solid",
              borderImage: `linear-gradient(
                  -90deg,
                  rgba(56, 65, 74, 0) 0%,
                  #38414a 49.83%,
                  rgba(56, 65, 74, 0) 100%
                )`,
              margin: "10px 0px",
              borderImageSlice: 1,
              borderRadius: "1px",
            }}
          ></Box>
          <Box sx={{ margin: "20px 0px" }}>
            <Typography
              sx={{
                fontSize: "var(--font-size-base)",
                fontWeight: "var(--font-weight-500)",
                color: "var(--white-color)",
              }}
            >
              If you’ve signed up using a referral code, enter it below to
              redeem extra points!
            </Typography>
            <Box className="redeem-btn">
              <Box className="input-referral">
                <TextField
                  label="Enter referral code"
                  id="fullWidth"
                  value={isreferralCode}
                  onChange={(e) => setIsReferralCode(e.target.value)}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  sx={{
                    "& .MuiInputBase-root": {
                      "@media (max-width: 600px)": {
                        width: "270px", // Width for screens smaller than 600px (mobile)
                      },
                      width: "350px",
                      backgroundColor: "rgba(51, 55, 71, 1)", // Set background color
                      borderRadius: "25px", // Add border radius
                      borderColor: "rgba(56, 65, 74, 1) !important", // Set border color to white
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(182, 194, 207, 1) !important", // Set label color to white
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(56, 65, 74, 1)!important", // Set outline border color to white
                    },
                    "& .MuiInputBase-input": {
                      color: "rgba(182, 194, 207, 1) !important", // Set input text color to white
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(182, 194, 207, 1) !important", // Set placeholder color
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderColor: "rgba(182, 194, 207, 1) !important", // Set hover border color to white
                    },
                    "& .MuiInput-underline:before": {
                      borderColor: "rgba(182, 194, 207, 1) !important", // Set focus border color to white
                    },
                    "& .MuiInput-underline:after": {
                      borderColor: "rgba(182, 194, 207, 1) !important", // Set active border color to white
                    },
                  }}
                />
              </Box>
              <Box className="input-button">
                <button
                  className="customPrimaryButton"
                  onClick={() => redeemCode()}
                >
                  Redeem
                </button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              borderTop: "1px solid",
              borderImage: `linear-gradient(
                  -90deg,
                  rgba(56, 65, 74, 0) 0%,
                  #38414a 49.83%,
                  rgba(56, 65, 74, 0) 100%
                )`,
              margin: "10px 0px",
              borderImageSlice: 1,
              borderRadius: "1px",
            }}
          ></Box>
          <Box className="continue-mission-button">
            <button
              className="customProfileButton"
              onClick={() => {
                handleClose();
                // setIsNewUser(false);
                localStorage.removeItem("isNewUser");
              }}
            >
              Continue to Missions
            </button>
          </Box>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default WelcomeModal;
