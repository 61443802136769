import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import "./ReferenceModal.css";
import Right from "../../assets/icons/right.svg";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "var(--background-color-light)", // Set the background color for the dialog
    color: "var(--white-color)",
    borderRadius: "20px",
    border: "1px solid rgba(56, 65, 74, 1)",
    maxWidth: "750px",
  },
});

const ReferenceModal = ({ handleClose, referenceOpen, titleColor, data }) => {
  const [copyText, setCopyText] = React.useState(false);
  const handleCode = () => {
    navigator.clipboard.writeText(data.referralCode ?? "ABC12345DEF");
    setCopyText(true);
  };

  React.useEffect(() => {
    setCopyText(false);
  }, [referenceOpen]);

  // Return empty fragement if the component is closed
  if (!referenceOpen) return <></>;
  return (
    <>
      <StyledDialog
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={referenceOpen}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            fontWeight: "var(--font-weight-400)",
            textTransform: "uppercase",
            fontSize: "var(--font-main-title)",
            fontFamily: "Impact, sans-serif !important",
          }}
          id="customized-dialog-title"
        >
          REFER A FRIEND
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--white-color)",
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Box
            sx={{
              backgroundColor: "#1c1d26",
              borderRadius: "15px",
              padding: "15px 20px",
              textAlign: "center",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontSize: "var(--font-sub-title)",
                fontWeight: "var(--font-weight-700)",
                fontFamily: "Inter Tight !important",
                color:
                  titleColor === "primary" ? "var(--light-green-color)" : "",
              }}
            >
              Your referral code is:
            </Typography>
            <Typography
              gutterBottom
              className="refer-code-text"
              sx={{ fontSize: "var(--font-large-content)" }}
            >
              {data?.referralCode || "ABC12345DEF"}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", marginTop: "25px" }}>
            <button
              className="customPrimaryButton"
              onClick={(e) => handleCode(e)}
            >
              Copy referral code
            </button>
          </Box>
          {copyText && (
            <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <Box sx={{ marginTop: "8px" }}>
                <img src={Right} alt="Right" />
              </Box>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "var(--font-size-base)",
                  color: "var(--light-green-color)",
                  fontFamily: "Inter Tight !important",
                  marginTop: "12px",
                }}
              >
                Successfully copied referral code
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              borderTop: "1px solid",
              borderImage: `linear-gradient(
                  -90deg,
                  rgba(56, 65, 74, 0) 0%,
                  #38414a 49.83%,
                  rgba(56, 65, 74, 0) 100%
                )`,
              margin: "25px 0px",
              borderImageSlice: 1,
              borderRadius: "1px",
            }}
          ></Box>
          <Box
            sx={{
              backgroundColor: "#1c1d26",
              borderRadius: "15px",
              padding: "15px 20px",
            }}
          >
            <Box
              gutterBottom
              sx={{
                fontFamily: "Inter Tight !important",
                fontSize: "var(--font-large-content)",
                color:
                  titleColor === "primary" ? "var(--light-green-color)" : "",
              }}
            >
              How to refer a friend
            </Box>
            <Box
              sx={{
                fontSize: "var(--font-size-base)",
                marginTop: "25px",
                fontFamily: "Inter Tight !important",
              }}
            >
              Refer a friend and earn{" "}
              <span
                style={{
                  fontFamily: "Inter Tight !important",
                  color: "var(--light-green-color)",
                  fontWeight: "var(--font-weight-bold)",
                }}
              >
                250 points
              </span>{" "}
              when they complete their first mission.
            </Box>
          </Box>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default ReferenceModal;
