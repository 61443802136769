import React, { useEffect, useState } from "react";
import "./WalletModal.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Wallet from "../../assets/icons/wallet1.svg";
import Wallet2 from "../../assets/icons/Wallet2.svg";
import QR from "../../assets/images/QR.png";
import { getBrowserDetails, getDeviceOS } from "../../helper";
import {
  connectSalona,
  fetchXaman,
  getWalletStatus,
  walletDisconnect,
} from "../../redux/reducers/Wallet";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/reducers/UserDetails";
import { useNavigate } from "react-router-dom";

// const xumm = new Xumm('2940e32d-3397-4a5f-a8fc-8b05feb1e44c') // Some API Key

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "var(--background-color-light)", // Set the background color for the dialog
    color: "var(--white-color)",
    borderRadius: "20px",
    border: "1px solid rgba(56, 65, 74, 1)",
    padding: "30px 25px",
  },
});
const WalletModal = ({ handleClose, walletOpen, data }) => {
  const [xamanQRVisible, setXamanQRVisible] = React.useState(false);
  const [phantomQRVisible, setPhantomQRVisible] = React.useState(false);
  const [xummAccount, setXummAccount] = useState("");
  const [appName, setAppName] = useState("");
  const [phantomProvider, setPhantomProvider] = useState(null);
  const [phantomPublicKey, setPhantomPublicKey] = useState(null);
  const [deviceOs, setDeviceOs] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [browserName, setBrowserName] = useState();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);
  const xummWalletId = useSelector(
    (state) => state.user?.userData?.xummWalletId
  );
  const salonaTokenId = useSelector(
    (state) => state.user?.userData?.salonaTokenId
  );

  const wallet = useSelector((state) => state.wallet.xamanData);
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(fetchUser(authToken));
  // }, [])
  useEffect(() => {
    getProvider();
  }, []);

  useEffect(() => {
    const browserName = getBrowserDetails();
    setBrowserName(browserName);
    const os = getDeviceOS();
    switch (os) {
      case "Android":
        setIsMobile(true);
        break;

      case "iOS":
        setIsMobile(true);
        break;
      case "Windows Phone":
        setIsMobile(true);
        break;

      default:
        setIsMobile(false);
        break;
    }
    setDeviceOs(os);
  }, []);

  const handleXamanSocketConnection = (data) => {
    try {
      var socket = new WebSocket(data.refs.websocketStatus);
      socket.onmessage = function (event) {
        console.log("🚀 ~ handleXamanSocketConnection ~ event:", event);
        const d = JSON.parse(event.data);
        if (d?.signed === true) {
          // user has approved request

          socket.close();

          setXamanQRVisible(false);
          setTimeout(async () => {
            dispatch(getWalletStatus(authToken));
          }, 1000);
          setTimeout(async () => {
            dispatch(fetchUser(authToken));
          }, 1000);
        }
      };
    } catch (error) {
      console.log("🚀 ~ handleXamanSocketConnection ~ error:", error);
    }
  };

  useEffect(() => {
    if (wallet) {
      handleXamanSocketConnection(wallet);
    }
  }, [wallet, data]);

  const getProvider = () => {
    if (window.solana && window.solana.isPhantom) {
      const provdr = window.phantom?.solana;
      if (provdr?.isPhantom) {
        setPhantomProvider(provdr);
      }
      return provdr;
    } else {
      // console.log('Phantom Wallet not detected. Please install Phantom Wallet and try again.')
      if (
        deviceOs === "Linux" ||
        deviceOs === "Windows" ||
        deviceOs === "Mac OS"
      ) {
        alert(
          "Phantom Wallet not detected. Please install Phantom Wallet and try again."
        );
        window.open("https://phantom.app/", "_blank");
      }
    }
  };
  const openInXaman = () => {
    // window.location.href = "Xumm://SignIn"
    window.location.href = wallet?.next?.always;
  };

  const connectPhantomMobileAdapter = (base) => {
    const token = localStorage.getItem("accessToken");
    const url = encodeURIComponent(`${window.location.origin}/?token=${token}`);
    const ref = encodeURIComponent(window.location.origin);
    window.location.href = `${base}/browse/${url}?ref=${ref}`;
  };

  const handleConnect = async () => {
    try {
      if (!phantomProvider) {
        getProvider();
      }
      const resp = await phantomProvider?.connect();
      const pubKey = resp.publicKey.toString();
      setPhantomPublicKey(pubKey);
      dispatch(connectSalona({ authToken, salonaTokenId: pubKey }));
      setTimeout(() => {
        dispatch(fetchUser(authToken));
      }, 1000);
      navigate("/profile");
    } catch (error) {
      // alert(`error : ${error} `)
      console.error("Failed to connect to Phantom Wallet:", error);
    }
  };

  const handleDisconnect = async () => {
    try {
      //  if (!phantomProvider) {
      //    getProvider()
      //  }
      const resp = await phantomProvider?.disconnect();
      setPhantomPublicKey(null);
      dispatch(
        walletDisconnect({
          authToken,
          data: {
            isXumm: false,
            isPhantom: true,
          },
        })
      );
      setTimeout(() => {
        dispatch(fetchUser(authToken));
      }, 1000);
      navigate("/profile");
    } catch (error) {
      console.error("Failed to connect to Phantom Wallet:", error);
    }
  };

  useEffect(() => {
    if (phantomProvider) {
      phantomProvider.on("accountChanged", (publicKey) => {
        if (publicKey) {
          // Set new public key and continue as usual
          setPhantomPublicKey(publicKey.toBase58());
          console.log(`Switched to xummAccount ${publicKey.toBase58()}`);
        }
      });
    }
  }, [phantomProvider]);

  // useEffect(() => {
  //   xumm.user.xummAccount?.then((a) => {
  //     setXummAccount(a ?? "");
  //   });
  //   xumm.environment.jwt?.then((j) => {
  //     setAppName(j?.app_name ?? "");
  //   });
  // }, []);

  // const logout = () => {
  //   xumm.logout();
  //   setAppName();
  //   setXummAccount("");
  // };
  const xamanHandle = () => {
    dispatch(fetchXaman(authToken));

    setXamanQRVisible(true);
    setPhantomQRVisible(false);
  };
  // const phantomHandle = () => {
  //   setPhantomQRVisible(true);
  //   setXamanQRVisible(false);
  // };
  React.useEffect(() => {
    setXamanQRVisible(false);
    setPhantomQRVisible(false);
    // setMangeWallet(false)
  }, [walletOpen]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const isModel = params.get("isModel");

    if (isModel) {
      handleConnect().then((res) => {
        // alert(`res : ${res}`)
        console.log("res", res);
        // alert(`authToken : ${authToken}`)
        setTimeout(() => {
          // alert(`Wallet connected successfully.`)
          dispatch(fetchUser(authToken));
        }, 1000);
      });
      // if (!solonaTokenData) {
      //   // alert(`handleConnect : ${salonaTokenId}`)
      //   handleConnect()
      //   setTimeout(() => {
      //     dispatch(fetchUser(authToken));
      //   }, 2000);
      // }else{
      //   handleDisconnect()
      //   setTimeout(() => {
      //     dispatch(fetchUser(authToken));
      //   }, 2000);
      // }
    }
  }, [walletOpen]);
  return (
    <>
      {" "}
      <StyledDialog
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={walletOpen}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            fontWeight: "var(--font-weight-400)",
            textTransform: "uppercase",
            fontSize: "var(--font-main-title)",
            fontFamily: "Impact, sans-serif !important",
          }}
          id="customized-dialog-title"
        >
          CONNECT CRYPTO WALLET
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--white-color)",
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {data.isXamanVisible && (
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12 }}
              className="wallet-section-detail"
            >
              <Grid item xs={6} md={1.5}>
                <img src={Wallet} alt="WalletICon" />
              </Grid>
              <Grid item xs={12} md={7.5} className="mission-detail">
                <Box className="wallet-text">Xaman Wallet (XRP)</Box>
                <Typography
                  className="mission-text-p"
                  sx={{ fontFamily: "Inter Tight !important" }}
                >
                  Connect your Xaman Wallet and earn{" "}
                  <span
                    style={{
                      color: "var(--light-green-color)",
                      fontWeight: "var(--font-weight-bold)",
                    }}
                  >
                    3000 Points
                  </span>
                  . Xaman is a self custody wallet for the XRP Ledger. See&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://xumm.app/"
                    style={{
                      textDecoration: "underline",
                      color: "var(--white-color)",
                      fontFamily: "Inter Tight !important",
                    }}
                  >
                    https://xumm.app/
                  </a>
                </Typography>
              </Grid>
              {!xamanQRVisible && (
                <Grid item xs={12} md={3} className="start-button-container">
                  <button
                    className="customProfileButton start-button"
                    onClick={() => {
                      if (xummWalletId) {
                        dispatch(
                          walletDisconnect({
                            authToken,
                            data: {
                              isXumm: true,
                              isPhantom: false,
                            },
                          })
                        );
                        setTimeout(() => {
                          dispatch(fetchUser(authToken));
                        }, 1000);
                      } else {
                        xamanHandle();
                      }
                    }}
                    // onClick={appName ? logout : (xummAccount === '' && !xumm.runtime.xapp ? xumm.authorize : null)}
                  >
                    {xummWalletId ? "Disconnect Xaman" : "Connect Xaman"}
                  </button>
                </Grid>
              )}

              {xamanQRVisible && (
                // {true && (
                <Grid
                  container
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  className="wallet-details"
                >
                  <Grid item xs={0} md={1.5}></Grid>
                  <Grid item xs={12} md={3}>
                    {/* <img src={QR} alt="WalletICon" /> */}
                    <img
                      src={wallet?.refs?.qrPng}
                      style={{ width: "170px" }}
                      alt="WalletICon"
                    />
                  </Grid>
                  <Grid item xs={12} md={7.5} className="mission-detail">
                    <Typography
                      className="mission-text-p"
                      sx={{ fontFamily: "Inter Tight !important" }}
                    >
                      Scan the QR code or click on the link below to open your
                      Xaman app. Follow the steps to connect your wallet to BPM
                      Missions.
                    </Typography>

                    {isMobile && (
                      <button
                        className="customPrimaryButton wallet-button"
                        onClick={() => openInXaman()}
                      >
                        Open in Xaman
                      </button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          {data.isPhantomVisible && (
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12 }}
              className="wallet-section-detail"
            >
              <Grid item xs={6} md={1.5}>
                <img src={Wallet2} alt="Wallet2ICon" />
              </Grid>
              <Grid item xs={12} md={7.5} className="mission-detail">
                <Box className="wallet-text">Phantom Wallet (SOL)</Box>
                <Typography
                  className="mission-text-p"
                  sx={{ fontFamily: "Inter Tight !important" }}
                >
                  Connect your Phantom Wallet and earn{" "}
                  <span
                    style={{
                      color: "var(--light-green-color)",
                      fontWeight: "var(--font-weight-bold)",
                    }}
                  >
                    2000 Points
                  </span>
                  . Phantom is a self custody wallet for Solana, Ethereum and
                  Polygon.See&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://phantom.app/"
                    style={{
                      textDecoration: "underline",
                      color: "var(--white-color)",
                      fontFamily: "Inter Tight !important",
                    }}
                  >
                    https://phantom.app/{" "}
                  </a>
                </Typography>
              </Grid>{" "}
              {!phantomQRVisible && (
                <Grid item xs={12} md={3} className="start-button-container">
                  {isMobile ? (
                    browserName == "Unknown" ? (
                      <button
                        className="customProfileButton start-button"
                        onClick={
                          !salonaTokenId ? handleConnect : handleDisconnect
                        }
                      >
                        {salonaTokenId ? "Disconnect Phantom" : "Connect Phantom"}
                      </button>
                    ) : (
                      <button
                        className="customProfileButton start-button"
                        onClick={() =>
                          !salonaTokenId
                            ? connectPhantomMobileAdapter(
                                "https://phantom.app/ul"
                              )
                            : handleDisconnect()
                        }
                      >
                        {salonaTokenId ? "Disconnect Phantom" : "Connect Phantom"}
                      </button>
                    )
                  ) : (
                    <button
                      className="customProfileButton start-button"
                      onClick={
                        !salonaTokenId ? handleConnect : handleDisconnect
                      }
                    >
                      {salonaTokenId ? "Disconnect Phantom" : "Connect Phantom"}
                    </button>
                  )}
                </Grid>
              )}
              {phantomQRVisible && (
                <Grid container className="wallet-details">
                  <Grid item xs={0} md={1.5}></Grid>
                  <Grid item xs={12} md={3}>
                    <img src={QR} alt="WalletICon" />
                  </Grid>
                  <Grid item xs={12} md={7.5} className="mission-detail">
                    <Typography
                      className="mission-text-p"
                      sx={{ fontFamily: "Inter Tight !important" }}
                    >
                      Scan the QR code or click on the link below to open your
                      Xaman app. Follow the steps to connect your wallet to BPM
                      Missions.
                    </Typography>
                    <button
                      className="customPrimaryButton wallet-button"
                      // onClick={() => setOpen(true)}
                    >
                      Open in Phantom
                    </button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default WalletModal;
