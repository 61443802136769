import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Navigation from "./components/Navigation/Navigation";
import CommonBack from "./components/CommonBackground/CommonBack";
import AuthNavBar from "./components/AuthNavBar/AuthNavBar";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "./redux/reducers/AuthUser";
import Loader from "./components/Loader/Loader";
import { fetchUser } from "./redux/reducers/UserDetails";
import { UserAuth } from "./components/Authcontext";
//video imports
import DesktopLoader from "../src/assets/desktop_loading.mp4";
import MobileLoader from "../src/assets/mobile_loading.mp4";

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { pageLoading } = UserAuth();
  const dispatch = useDispatch();
  const [isVideoPlayed, setIsVideoPlayed] = useState(
    sessionStorage.getItem("videoPlayed") || false
  );
  const [videoSource, setVideoSource] = React.useState(DesktopLoader);

  function handleVideoPlaying() {
    sessionStorage.setItem("videoPlaying", "true");
    window.dispatchEvent(new Event("storage"));
  }

  document.addEventListener("visibilitychange", handleVideoPlaying);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("android") || userAgent.includes("iphone")) {
      setVideoSource(MobileLoader);
    } else {
      setVideoSource(DesktopLoader);
    }
  }, []);

  const handleVideoEnd = () => {
    sessionStorage.setItem("videoPlaying", "false");
    sessionStorage.setItem("videoPlayed", "true");
    window.dispatchEvent(new Event("storage"));
    setIsVideoPlayed(true);
  };

  const handleGetStarted = () => {
    sessionStorage.setItem("videoPlaying", "false");
    sessionStorage.setItem("videoPlayed", "true");
    window.dispatchEvent(new Event("storage"));
    setIsVideoPlayed(true);
  };

  useEffect(() => {
    let userFromLocalStorage =
      localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
    if (userFromLocalStorage) {
      dispatch(login());
      dispatch(fetchUser());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <>
      {!isAuthenticated && !isVideoPlayed ? (
        <>
          <div className="video-overlay">
            <video
              id="introVideo"
              loop={false}
              muted
              autoPlay={true}
              playsInline={true} //ios
              preload="auto"
              onEnded={handleVideoEnd}
            >
              <source src={videoSource} type="video/mp4"></source>
            </video>
            <button
              className="customPrimaryButton get-started-button"
              onClick={handleGetStarted}
            >
              Get Started
            </button>
          </div>
        </>
      ) : (
        <BrowserRouter>
          {pageLoading ? (
            <Loader />
          ) : (
            <>
              {isAuthenticated ? <AuthNavBar /> : <Navbar />}
              <CommonBack />
              <Navigation />
              <Footer />
            </>
          )}
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
