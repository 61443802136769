import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/index";
import axios from "axios";
import { refreshToken } from "../reducers/RefreshToken";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Add an interceptor to handle token refresh on 401 responses
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const response = await store.dispatch(refreshToken());
      const newToken = response.payload.result.jwtToken;
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default store;
