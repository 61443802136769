import React from "react";
import Box from "@mui/material/Box";

import './CommonBack.css'

const CommonBack = () => {
  return (
    <Box className="common-bg">
    </Box>
  );
};

export default CommonBack;
